.footer {
    width: 100%;
    background: #274448;
    color: #FFF;
}
.footer .socialMediaIcons {
    background: transparent;
    text-align: center !important;
    padding: 10px 0 10px 0;
}

.footer .socialMediaIcons span {
    display: inline;
}

.footer .socialMediaIcons a img {
    display: inline-block;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    border: none;
}
.footer .socialMediaIcons a {
    position: relative;
    display: inline-block;
    margin: 0 15px;
    text-decoration: none;
}
.footer .socialMediaIcons a:before {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.65);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    color: #FFFFFF;
    content: attr(data-icon-text);
    display: block;
    left: 20%;
    opacity: 0;
    top: 0;
    white-space: nowrap;
    z-index: 10;
}
.footer .socialMediaIcons a,
.footer .socialMediaIcons a:before {
    -webkit-transition: all linear 300ms;
    -moz-transition: all linear 300ms;
    -o-transition: all linear 300ms;
    transition: all linear 300ms;
}

.footer .socialMediaIcons a:hover:before {
    opacity: 1;
    top: -20%;
}
.footer .socialMediaIcons span:hover a {
    opacity: 0.35;
}
.footer .socialMediaIcons span a:hover {
    opacity: 1;
}
.footer .socialMediaIcons a img:first-child,
.footer .socialMediaIcons span:hover a img:first-child + img,
.footer .socialMediaIcons span:hover a:hover img:first-child {
    display: none;
}
.footer .socialMediaIcons span:hover a img:first-child,
.footer .socialMediaIcons span:hover a:hover img:first-child + img {
    display: inline-block;
}

.footer .footerColumns h3.footerColumnHeader {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
    margin: 15px 0;
    text-transform: uppercase;
    padding: 0;
}

.footer .footerColumns p {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-align: justify;
}

.footer .footerColumns .usefulLinks, .footer .footerColumns .contactUs {
    padding: 0;
    list-style-type: none;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
}

.footer .footerColumns .usefulLinks li, .footer .footerColumns .contactUs li {
    padding: 8px 0;
    border-bottom: 1px solid #6e6e6e;
}

.footer .footerColumns .usefulLinks li:first-child, .footer .footerColumns .contactUs li:first-child {
    padding-top: 0;
}

.footer .footerColumns .usefulLinks li:last-child, .footer .footerColumns .contactUs li:last-child {
    border-bottom: 0;
}

.footer .footerColumns .usefulLinks .linkDefault i {
    float: left;
}

.footer .footerColumns .usefulLinks .linkDefault span {
    margin-left: 10px;
}